/* See https://github.com/rails/request.js#how-to-use */
import { get } from "@rails/request.js"

window.addEventListener("turbo:load", update_javinto_app_tasks);
window.addEventListener("turbo:load", update_javinto_app_task);

/* Fires in a modal dialog */
window.addEventListener("turbo:frame-load", update_javinto_app_task);

/* Handle multiple tasks */
function update_javinto_app_tasks(event) {
  /* If there is a Tasks container, then refresh it every 3 seconds */
  const tasks_container= document.querySelector('#tasks_container');
  if (tasks_container) {
    refresh_javinto_app_jobs_tasks_container(tasks_container);

    const timeout= tasks_container.dataset.timeout || 4000;
    setInterval( function() {
      refresh_javinto_app_jobs_tasks_container(tasks_container);
    }, timeout);
  }  
}

/* Handle single task */
let taskInterval;
function update_javinto_app_task(event) {
  /* Now look for single tasks */
  const task_container= document.querySelector('#task_container');
  if (task_container) {
    refresh_javinto_app_jobs_task(task_container);

    const timeout= task_container.dataset.timeout || 1000;
    taskInterval= setInterval( function() {
      refresh_javinto_app_jobs_task(task_container);
    }, timeout);
  }  
}


/* Here we poll every 3 seconds for tasks updates if defined any */
async function refresh_javinto_app_jobs_tasks_container(tasks_container) {
  const data_ids= tasks_container.dataset.ids
  let params= {};

  if (data_ids) {
    params={ids: data_ids}
  }

  const getRequest= await get(tasks_container.dataset.url, {
    responseKind: "json",    // andere opties: html (default), json, turbo_stream
    query: params
  })
  .then((response) => response.json)
  .then((json) => {
    console.log(json);
    
    if (json['tasks_finished']==true) {
      // We're done with a task, refresh the whole window
      location.reload();
    } else {
      const tasks_container= document.querySelector('#tasks_container');
      if (tasks_container) {
        tasks_container.dataset.ids= json['task_ids'];

        // Show running tasks (HTML) in container
        const task_list= tasks_container.querySelector('.tasks');
        if (task_list) task_list.innerHTML= json['html'];      
      }
    }
  });
}


/* Here we poll every 3 seconds for tasks updates if defined any */
async function refresh_javinto_app_jobs_task(task_container) {
  const data_ids= task_container.dataset.ids
  const task_progress= task_container.querySelector('#task_progress');
  const task_result= task_container.querySelector('#task_result');

  const task_progress_msg= task_container.querySelector('#task_progress_msg');
  const task_result_msg= task_container.querySelector('#task_result_msg');

  const getRequest= await get(task_container.dataset.url, {
    responseKind: "json",    // andere opties: html (default), json, turbo_stream
    query: {}
  })
  .then((response) => response.json)
  .then((json) => {
    // console.log(json['i_progress_nr']);
    // Update Progressbar layout
    if (task_progress) {task_progress.innerHTML= json['html']}

  }).catch((e) => {
    // No task anymore, finished
    // console.error(e.message)
    clearInterval(taskInterval);
    if (task_progress) task_progress.remove();
    if (task_progress_msg) task_progress_msg.remove();
    if (task_result) task_result.classList.remove('hide');
    if (task_result_msg) task_result_msg.classList.remove('hide');
  });
}