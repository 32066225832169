window.addEventListener("turbo:load", function(event) {bind_sortable(document)});

function bind_sortable(html) {
  /* Sortable list containers: */
  $('[data-sort-url]').sortable({
    axis: 'y',
    cursor: 'crosshair',
    opacity: 0.4,
    handle: '.jav-draggable',
    update: function(event, ui){
      $.ajax({
        type: 'post',
        data: $(this).sortable('serialize'),
        dataType: 'script',
        url: $(this).data('sort-url')})
    }
  });
}