// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//---- jQuery(UI) ----
import './src/jquery'
import 'jquery-ui/dist/jquery-ui'

// https://github.com/chartjs/Chart.js
/* Ook via Yarn beschikbaar, maar dan moeten we de inline JS ombouwen naar bijv. een Stimulus controller */
require("./packs/chart")

//---- JavintoAppPages
require("./packs/content/content_assets")
require("./packs/content/content_pages")

//---- netlawacademy.nl ----
require("./packs/bootstrap_activation");
require("./packs/swipe.js");

require("./packs/pagination");
require("./packs/main");
require("./packs/mail_messages");
require("./packs/multi_records");
require("./packs/sortable");
require("./packs/table_sortable");
require("./packs/tasks.js");

/**
/* Skip ActionText in favour of TinyMCE, but keep it installed 
/* import "trix"
/* import "@rails/actiontext"
**/


/* ActionText changes. Gebruik <p> ipv <div> en <h2> ipv <h1>*/
// Trix.config.blockAttributes.default= {
//     tagName: "p",
//     parse: false
// }

// Trix.config.blockAttributes.heading1 = {
//     tagName: "h2",
//     terminal: true,
//     breakOnReturn: true,
//     group: false
// }