import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("Hello, EvaluationResultsController!", this.element)
  }

  // Triggered on Button
  paginate_evaluation_form(event) {
    const btnElm= event.target;
    // console.log("Clicking on button " + btnElm.textContent);

    const window1= document.querySelector('#evaluation');
    const window2= document.querySelector('#questions');

    if (window1.classList.contains('d-none')) {
      window1.classList.remove('d-none');
      window2.classList.add('d-none');
    } else if (window2.classList.contains('d-none')) {
      window2.classList.remove('d-none');
      window1.classList.add('d-none');    
    }

    event.preventDefault();
    event.stopPropagation();
  }

  toggle_question_g(event) {
    const targetElm= event.target;
    console.log("Changed " + targetElm.name);

    const questionWrapper= document.querySelector('#er_wrapper_course_g');
    const questionInput= document.querySelector('#evaluation_result_course_g');
    questionWrapper.classList.toggle('d-none');
    questionInput.checked= false;
  }
 

}
