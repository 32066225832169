import { Controller } from "@hotwired/stimulus"

/* Zie admin_form_defaults() waar deze controller gebonden wordt aan alle admin forms */
export default class extends Controller {

  connect() {
    // console.log("Hello, Forms!", this.element);

    // Auto focus on first relevant field
    // $("input[type!='hidden'][type!='submit'][type!='reset']:enabled, select:enabled, textarea:enabled", this.element).not('.nofocus').first().focus();
    this.element.querySelector("input:not([type='hidden'],[type='submit'],[type='reset'],[disabled],.nofocus), select:not([disabled], .nofocus), textarea:not([disabled], .nofocus)").focus();
  }

  // Voor Select boxes, want dan wordt de nieuwe waarde niet zichtbaar zolang die is-invalid class erop staat
  clear_invalid_class(event) {
    const targetElm= event.target;
    console.log(targetElm.classList);
    targetElm.classList.remove('is-invalid');
  }
}
