import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"                         // https://github.com/rails/request.js#how-to-use
import { freeAutocompleteOptions } from "./autocomplete_controller.js"

export default class extends Controller {

  connect() {
    // console.log("Hello, CoursesController!", this.element)
  }

  // Triggered on Select#course_course_type_id
  change_course_type(event) {
    const selectElm= event.target;
    // console.log("Changing courseType value " + selectElm.value);

    if (selectElm.value) {
      // Type opleiding geselecteerd
      // Lijst van codes Opleidingstype { 7: "nlbu", 8: "offic", 10: "nlac", 69: "sect", 73: "web", 78: "nex" }
      const course_type_codes= JSON.parse(selectElm.dataset.courseTypeCodes )
      const course_type_code= course_type_codes[selectElm.value];
      const organisedByElm= document.querySelector("[name='course[organised_by]']");

      // Verwijder Autocomplete (en focus!) van Uitvoering
      if ($(organisedByElm).autocomplete( "instance" )) {
        $(organisedByElm).autocomplete( "destroy" ).off('focus');
      }

      // QR-code gebruiken j/n?
      const use_incheck_elm= document.querySelector('#course_use_incheck');
      if (use_incheck_elm) {
        use_incheck_elm.checked= !['offic', 'web', 'nex'].includes(course_type_code)
      }

      // Type opleiding bepaalt naam Uitvoering
      organisedByElm.value= "";
      switch(course_type_code) {
      case 'nlac':
        organisedByElm.value= "NetlawAcademy";
        break;
      case 'nlbu':
        organisedByElm.value= "Netlaw bureau";
        break;
      case 'offic':
        // Inhouse opleiding: kantoor
        $(organisedByElm).autocomplete(freeAutocompleteOptions).focus(function(event) {
          // Show autocomplete list directly on focus    
          $(this).autocomplete('search');
        });
        break;
      }

    }
    // event.preventDefault();
    // event.stopPropagation();
  }

  // Triggered on Select#course_section_id
  // Plaats naam van de Sectie in het uitveld Uitvoering
  async change_section(event) {
    const selectElm= event.target;
    // console.log("Changing section value " + selectElm.value);

    // getRequest waarmee je zelfs turbo_stream formats kunt aanroepen
    // Vraag hiermee de geselecteerde Sectie op (JSON)
    const getRequest= await get(selectElm.dataset.sectionUrl + '/' + selectElm.value, {
      responseKind: "json",    // andere opties: json, turbo_stream
    })
    .then((response) => response.json)
    .then((json) => {
      // console.log(json.name);
      const elm= document.querySelector("[name='course[organised_by]']");
      if (elm) {elm.value= `Sectie ${json.name}`}
    });

  }

}
