document.addEventListener("click", function(event) {
  const elm= event.target;

  if (elm.classList.contains('th') && elm.classList.contains('sort')) {
    const sort_mod = elm.dataset.sortMod;
    const sort_col = elm.dataset.sortCol;
    const sort_dir = elm.classList.contains('down') ? 'up' : 'down';

    const urlParams = new URLSearchParams(location.search);
    urlParams.set('sort[col]', sort_col);
    urlParams.set('sort[dir]', sort_dir);

    if (sort_mod) {
      urlParams.set('sort[mod]', sort_mod);
    } else {
      urlParams.delete('sort[mod]');
    }
    // location.hash=null; // Remove anchor
    location.search= urlParams.toString();
  }
})