/* From https://dev.to/djchadderton/making-tinymce-work-with-rails-turbolinks-and-stimulus-2ooa */
import { Controller } from "@hotwired/stimulus"

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

/* Omdat er ook een aantal basis scripts geladen moeten worden, voeg EVT de volgende regel toe:
 * Rails.application.config.assets.paths << Rails.root.join("node_modules/tinymce")
*/

// Import icons
import 'tinymce/icons/default';

// Import theme
import 'tinymce/themes/silver/theme';

// Import skin: see application.scss
// import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/models/dom';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/fullscreen';

export default class extends Controller {
  // Targets zijn een beetje overbodig. We kunnen gewoon met selector uit de voeten. Bovendien kun je maar één target tegelijk in een form gebruiken!
  static targets = ['content_input', 'media_input']

  initialize () {

    // Basis configuratie
    let base_config= {
      license_key: 'gpl', 
      skin: false,
      plugins: ['image', 'media', 'link', 'anchor', 'lists', 'code', 'charmap', 'fullscreen', 'table'],
      menubar: false,
      toolbar: 'undo redo | pastetext | blocks | bold italic superscript | image media| anchor link | numlist bullist | alignleft aligncenter alignright | charmap code | table fullscreen',
      contextmenu: 'link image removeformat',

      // Image plugin    
      images_upload_url: '/content/content_assets/upload',
      image_caption: true,
      image_list: '/content/content_assets/tinymce_image_list?type[]=ContentPage&type[]=ContentPageTranslation&type[]=none',

      // Link plugin
      link_list: '/content/content_pages/tinymce_link_list',
      language: 'nl',
      language_url: RAILS_ASSET_URL('/tinymce/langs/nl.js'),
      content_css: RAILS_ASSET_URL('/tinymce_content.css'),

      // Video
      media_dimensions: false,
      media_poster: false,

      // Table
      table_appearance_options: false,        // This option allows you to disable some of the options available to a user when inserting or editing a table. When set to false the following fields will not appear: Cell spacing, Cell padding, Border and Caption.
      table_advtab: false,                    // This option makes it possible to disable the advanced tab in the table dialog box. The advanced tab allows a user to input style, border color and background color values.

      branding: false,
      toolbar_sticky: true,
      statusbar: true, 

      paste_as_text: true,
      relative_urls: false,

      block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5',

      formats: {
        // Changes the alignment buttons to add a class to each of the matching selector elements
        alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-left' },
        aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-center' },
        alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-right' },
        alignjustify: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-justify' }
      }
    }

    // Default main Editor voor HTML-pagina's
    this.main_content_config= Object.assign({}, base_config);   // Clone base_config

    // Image and Media Editor voor HTML-pagina's
    this.media_content_config= Object.assign({}, base_config);   // Clone base_config

    // Full Editor voor back-end content
    this.full_config= Object.assign({}, base_config);   // Clone base_config
    this.full_config.toolbar= 'undo pastetext | blocks | bold italic underline | image link | numlist bullist | alignleft aligncenter | indent outdent | charmap fullscreen';

    // Medium Editor voor back-end content (voormalige Inline Editor)
    this.medium_config= Object.assign({}, base_config);   // Clone base_config
    this.medium_config.toolbar= 'undo pastetext | blocks | bold italic underline | link | numlist bullist | alignleft aligncenter | indent outdent | table image | charmap fullscreen';

// toolbar: 'undo redo | pastetext | blocks | bold italic superscript | image media| anchor link | numlist bullist | alignleft aligncenter alignright | charmap code | table fullscreen',    

    // Mini editor for captions etc
    this.mini_config= Object.assign({}, base_config);   // Clone base_config
    this.mini_config.statusbar= false;
    this.mini_config.toolbar= 'undo | bold italic superscript | bullist link';
    this.mini_config.contextmenu= 'link removeformat';

  }

  connect () {
    const img_upload_url= this.element.dataset.imagesUploadUrl;
    const img_list_url= this.element.dataset.imageListUrl;
    const link_list_url= this.element.dataset.linkListUrl;

    // Main Editor voor HTML-pagina's
    try {
      if( img_upload_url) this.main_content_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.main_content_config['image_list']= img_list_url;
      if( link_list_url) this.main_content_config['link_list']= link_list_url;

      let main_config = Object.assign({ target: this.content_inputTarget }, this.main_content_config)
      tinymce.init(main_config)
    } catch (error) {
      // Input target not defined?
      // console.error(error);
    }

    // Image and Media Editor voor HTML-pagina's
    try {
      if( img_upload_url) this.media_content_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.media_content_config['image_list']= img_list_url;
      if( link_list_url) this.media_content_config['link_list']= link_list_url;

      let media_config = Object.assign({ target: this.media_inputTarget }, this.media_content_config)
      tinymce.init(media_config)
    } catch (error) {
      // Input target not defined?
      // console.error(error);
    }

    // Full Editor voor back-end content
    try {
      if( img_upload_url) this.full_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.full_config['image_list']= img_list_url;
      if( link_list_url) this.full_config['link_list']= link_list_url;

      let full_config = Object.assign({ selector: 'textarea.tinymce.full' }, this.full_config)

      tinymce.init(full_config)
    } catch (error) {
      // Input target not defined?
      console.error(error);
    }

    // Medium Editor voor back-end content (voormalige Inline Editor)
    try {
      if( img_upload_url) this.medium_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.medium_config['image_list']= img_list_url;
      if( link_list_url) this.medium_config['link_list']= link_list_url;

      let medium_config = Object.assign({ selector: 'textarea.tinymce.medium' }, this.medium_config)

      tinymce.init(medium_config)
    } catch (error) {
      // Input target not defined?
      console.error(error);
    }

    try {
      let mini_config = Object.assign({ selector: 'textarea.tinymce.mini', height: 200}, this.mini_config)
      tinymce.init(mini_config)
    } catch (error) {
      // Input target not defined?
      // console.error(error);
    }

  }

  disconnect () {
    tinymce.remove()
  }
}
